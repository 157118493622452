export enum AppState {
    Init = 0,
    Revealed = 1,
}

export enum PokerMode {
    Fibonacci = 0,
    FibonacciPlus = 1,
    Linear = 2,
    TShirt = 3,
    YesNo = 4
}